import React, { useEffect, useState } from 'react';
import './BookModal.css';
import { db } from '../firebaseConfig';
import { addDoc, collection, doc, getDoc, getDocs } from 'firebase/firestore';

interface BookModalProps {
    onClose: () => void;
    promoId: string; // Add promoId as a prop
}

const BookModal: React.FC<BookModalProps> = ({ onClose, promoId }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [clientInfo, setClientInfo] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
    });
    const [providers, setProviders] = useState<{ id: string, name: string }[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<string>('');
    const [appointmentDate, setAppointmentDate] = useState<Date | null>(null);
    const [appointmentTime, setAppointmentTime] = useState<string>('');
    const [additionalNotes, setAdditionalNotes] = useState<string>('');
    const [availableTimes, setAvailableTimes] = useState<string[]>([]);
    const [isNextEnabled, setIsNextEnabled] = useState(false);  // New state for Next button
    const [loading, setLoading] = useState(false);
    const [isBooked, setIsBooked] = useState(false);

    useEffect(() => {
        const fetchProviders = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'providers'));
                const providerData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name as string,
                }));
                setProviders(providerData); // Set providers with id and name
            } catch (error) {
                console.error('Error fetching providers:', error);
            }
        };

        fetchProviders();

        const generateAvailableTimes = () => {
            const times: string[] = [];
            for (let hour = 11; hour <= 17; hour++) {
                const startMinute = hour === 17 ? 0 : 0;
                const endMinute = hour === 17 ? 30 : 55;

                for (let minute = startMinute; minute <= endMinute; minute += 5) {
                    const formattedTime = formatTime(hour, minute);
                    times.push(formattedTime);
                }
            }
            setAvailableTimes(times);
        };

        generateAvailableTimes();
    }, []);


    useEffect(() => {
        // Validation check for each step
        if (currentStep === 1) {
            setIsNextEnabled(
                clientInfo.firstName.trim() !== '' &&
                clientInfo.lastName.trim() !== '' &&
                clientInfo.email.trim() !== '' &&
                clientInfo.phone.trim() != ''
            );
        } else if (currentStep === 2) {
            setIsNextEnabled(selectedProvider.trim() !== '');
        } else if (currentStep === 3) {
            setIsNextEnabled(appointmentDate !== null && appointmentTime.trim() !== '');
        } else {
            setIsNextEnabled(true);
        }
    }, [currentStep, clientInfo, selectedProvider, appointmentDate, appointmentTime]);


    const formatTime = (hour: number, minute: number) => {
        const formattedHour = hour > 12 ? hour - 12 : hour;
        const formattedMinute = minute < 10 ? `0${minute}` : minute;
        const period = hour >= 12 ? 'PM' : 'AM';
        return `${formattedHour}:${formattedMinute} ${period}`;
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            // Fetch the promo document using promoId
            const promoDocRef = doc(db, 'promos', promoId);
            const promoDocSnap = await getDoc(promoDocRef);

            if (!promoDocSnap.exists()) {
                console.error("Promo not found");
                return;
            }

            const promoName = promoDocSnap.data().name || 'Unknown Promo';
            const promoSecondName = promoDocSnap.data().second_name || '';
            const fullPromoName = promoSecondName ? `${promoName} (${promoSecondName})` : promoName;


            const providerDocRef = doc(db, 'providers', selectedProvider); // Use provider ID here

            const appointmentDateTime = new Date(appointmentDate!);
            const [hours, minutes] = appointmentTime.split(':');
            appointmentDateTime.setHours(parseInt(hours, 10));
            appointmentDateTime.setMinutes(parseInt(minutes, 10));

            // Store the booking data, with provider ID instead of name
            await addDoc(collection(db, 'promo_bookings'), {
                selected_promo: promoDocRef,
                promo_name: fullPromoName,  // Store the full promo name with second name, if applicable
                fname: clientInfo.firstName,
                mname: clientInfo.middleName || '',
                lname: clientInfo.lastName,
                email: clientInfo.email,
                phone: clientInfo.phone,
                selected_provider: providerDocRef, // Reference to provider document using ID
                appointment: appointmentDateTime,
                note: additionalNotes || '',
                created_on: new Date(),
            });

            const bookingDetails = {
                clientName: `${clientInfo.firstName} ${clientInfo.middleName ? `${clientInfo.middleName} ` : ''}${clientInfo.lastName}`,
                clientEmail: clientInfo.email,
                clientPhone: clientInfo.phone,
                promoName: fullPromoName,  // Use fullPromoName here
                providerName: selectedProvider,
                date: appointmentDateTime,
                notes: additionalNotes || 'No additional notes provided.'
            };


            await sendTelegramNotification(bookingDetails);

            setIsBooked(true);  // Booking is confirmed

        } catch (error) {
            console.error("Error booking appointment:", error);
        } finally {
            setLoading(false);
        }
    };


    const sendTelegramNotification = async (bookingDetails: any) => {
        const botToken = '7835833788:AAGDeIFkgexgVFcqcNHk9rZQ6ME6Ip-cK70';
        const chatId = '7505740377';

        const appointmentDate = new Date(bookingDetails.date);
        const formattedDate = appointmentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        const formattedTime = appointmentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });

        const provider = providers.find((provider) => provider.id === bookingDetails.providerName);
        const providerName = provider ? provider.name : 'Unknown Provider';

        // Format the client name with middle name if provided
        const clientName = bookingDetails.clientName;  // This will contain the full name

        const message = `New Promo Booking from ${clientName}
Promo: ${bookingDetails.promoName} 
Provider: ${providerName}
Date: ${formattedDate} at ${formattedTime}
Client Info:
- Email: ${bookingDetails.clientEmail}
- Phone: ${bookingDetails.clientPhone}
Additional Notes: ${bookingDetails.notes || 'None'}`;


        const url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}`;

        try {
            const response = await fetch(url, { method: 'GET' });
            if (!response.ok) throw new Error('Failed to send Telegram notification');
        } catch (error) {
            console.error('Error sending Telegram notification:', error);
        }
    };


    const nextStep = () => {
        if (currentStep < 5) setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value ? new Date(e.target.value) : null;
        if (selectedDate) {
            const dayOfWeek = selectedDate.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 1) {
                alert('Sundays and Mondays are not available for appointments.');
                return;
            }
        }
        setAppointmentDate(selectedDate);
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <div className="modal-overlay-promo" onClick={onClose}>
            <div className="modal-content-promo" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-promo" onClick={onClose}>×</button>

                <div className="progress-bar-promo">
                    <div style={{ width: `${(currentStep / 5) * 100}%` }} className="progress-promo"></div>
                </div>

                {isBooked ? (
                    <div className="confirmation-promo">
                        <h2>Booking Confirmed</h2>
                        <p>Your appointment has been successfully booked!.</p>
                    </div>
                ) : (
                    <>

                        {currentStep === 1 && (
                            <section className="promo-form-section-promo">
                                <h2>Client Info</h2>
                                <form>
                                    <div className="form-group-promo">
                                        <input
                                            type="text"
                                            id="firstName" // Add an id here
                                            placeholder=" "
                                            value={clientInfo.firstName}
                                            onChange={(e) => setClientInfo({ ...clientInfo, firstName: e.target.value })}
                                            required
                                        />
                                        <label htmlFor="firstName" className="floating-label-promo">First Name</label> {/* Link label to the input */}
                                    </div>

                                    <div className="form-group-promo">
                                        <input
                                            type="text"
                                            id="middleName" // Add an id here
                                            placeholder=" "
                                            value={clientInfo.middleName}
                                            onChange={(e) => setClientInfo({ ...clientInfo, middleName: e.target.value })}
                                        />
                                        <label htmlFor="middleName" className="floating-label-promo">Middle Name (optional)</label> {/* Link label to the input */}
                                    </div>

                                    <div className="form-group-promo">
                                        <input
                                            type="text"
                                            id="lastName" // Add an id here
                                            placeholder=" "
                                            value={clientInfo.lastName}
                                            onChange={(e) => setClientInfo({ ...clientInfo, lastName: e.target.value })}
                                            required
                                        />
                                        <label htmlFor="lastName" className="floating-label-promo">Last Name</label> {/* Link label to the input */}
                                    </div>

                                    <div className="form-group-promo">
                                        <input
                                            type="email"
                                            id="email" // Add an id here
                                            placeholder=" "
                                            value={clientInfo.email}
                                            onChange={(e) => setClientInfo({ ...clientInfo, email: e.target.value })}
                                            required
                                        />
                                        <label htmlFor="email" className="floating-label-promo">Email</label> {/* Link label to the input */}
                                    </div>

                                    <div className="form-group-promo">
                                        <input
                                            type="tel"
                                            id="phone" // Add an id here
                                            placeholder=" "
                                            value={clientInfo.phone}
                                            onChange={(e) => setClientInfo({ ...clientInfo, phone: e.target.value })}
                                        />
                                        <label htmlFor="phone" className="floating-label-promo">Phone</label> {/* Link label to the input */}
                                    </div>

                                </form>
                            </section>
                        )}

                        {currentStep === 2 && (
                            <section>
                                <h2>Provider</h2>
                                <select
                                    value={selectedProvider}
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    required
                                >
                                    <option value="">Select a Provider</option>
                                    {providers.map((provider) => (
                                        <option key={provider.id} value={provider.id}>
                                            {provider.name}
                                        </option>
                                    ))}
                                </select>
                            </section>
                        )}
                        {currentStep === 3 && (
                            <section>
                                <h2>Appointment</h2>
                                <label className='date-time-label-promo'>
                                    Date:
                                    <input
                                        type="date"
                                        value={appointmentDate ? appointmentDate.toISOString().substring(0, 10) : ''}
                                        onChange={handleDateChange}
                                        min={today}
                                        required
                                        className='date-field-promo'
                                    />
                                </label>
                                <label className='date-time-label-promo'>
                                    Time:
                                    <select
                                        value={appointmentTime}
                                        onChange={(e) => setAppointmentTime(e.target.value)}
                                        required
                                        className='time-field-promo'
                                    >
                                        <option value="">Select Time</option>
                                        {availableTimes.map((time, index) => (
                                            <option key={index} value={time}>{time}</option>
                                        ))}
                                    </select>
                                </label>
                            </section>
                        )}

                        {currentStep === 4 && (
                            <section className='textarea-promo'>
                                <h2>Additional Notes</h2>
                                <textarea
                                    value={additionalNotes}
                                    onChange={(e) => setAdditionalNotes(e.target.value)}
                                    placeholder="Any additional notes for the provider?"
                                />
                            </section>
                        )}

                        {currentStep === 5 && (
                            <section className="summary-section-promo">
                                <h2>Summary</h2>
                                <div className="summary-details-promo">
                                    <div className="summary-item-promo">
                                        <span className="summary-label-promo">Client Info:</span>
                                        <span className="summary-value-promo">{clientInfo.firstName} {clientInfo.lastName} ({clientInfo.email}, {clientInfo.phone})</span>
                                    </div>
                                    <div className="summary-item-promo">
                                        <span className="summary-label-promo">Provider:</span>
                                        <span className="summary-value-promo">
                                            {providers.find(provider => provider.id === selectedProvider)?.name || 'Unknown Provider'}
                                        </span>
                                    </div>
                                    <div className="summary-item-promo">
                                        <span className="summary-label-promo">Appointment:</span>
                                        <span className="summary-value-promo">{appointmentDate?.toLocaleDateString()} at {appointmentTime}</span>
                                    </div>
                                    <div className="summary-item-promo">
                                        <span className="summary-label-promo">Notes:</span>
                                        <span className="summary-value-promo">{additionalNotes || 'No additional notes provided.'}</span>
                                    </div>
                                </div>
                            </section>
                        )}


                        <div className="modal-buttons-promo">
                            {currentStep > 1 && !loading && (  // Only show Back button if not loading
                                <button
                                    className="prev-btn-promo"
                                    onClick={prevStep}
                                    disabled={loading}  // Disable Prev button while loading
                                >
                                    Back
                                </button>
                            )}
                            {currentStep < 5 ? (
                                <button
                                    className="next-btn-promo"
                                    onClick={nextStep}
                                    disabled={!isNextEnabled || loading}  // Disable Next button if form is not valid or loading
                                >
                                    Next
                                </button>
                            ) : loading ? (
                                <div className="loading-spinner-promo"></div>
                            ) : (
                                <button
                                    onClick={handleSubmit}
                                    disabled={loading}  // Disable Submit button during submission
                                >
                                    Submit Booking
                                </button>
                            )}
                        </div>



                    </>
                )}
            </div>
        </div >
    );
};

export default BookModal;
