import React, { useEffect, useState } from 'react';
import './Promos.css';
import { Link } from 'react-router-dom'; // Import Link for routing
import { db } from '../firebaseConfig';
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { Helmet } from 'react-helmet';

interface Promo {
    id: string;
    cover: string;
    name: string;
    second_name: string | null; // Allow null
    desc: string;
    focus: boolean;
}

const Promos: React.FC = () => {
    const [focusedPromo, setFocusedPromo] = useState<Promo | null>(null);
    const [promos, setPromos] = useState<Promo[]>([]);
    const [displayCount, setDisplayCount] = useState(6);
    const [coverImage, setCoverImage] = useState<string | null>(null);
    const [coverDescription, setCoverDescription] = useState<string | null>(null);

    useEffect(() => {
        const fetchCoverData = async () => {
            try {
                const coverCollection = collection(db, 'cover');
                const coverQuery = query(coverCollection, where('page_name', '==', 'promos'));
                const coverSnapshot = await getDocs(coverQuery);

                coverSnapshot.forEach(doc => {
                    const data = doc.data();
                    setCoverImage(data.cover);
                    setCoverDescription(data.description);
                });
            } catch (error) {
                console.error("Error fetching cover data: ", error);
            }
        };

        fetchCoverData();
    }, []);

    useEffect(() => {
        const fetchPromos = async () => {
            try {
                const promosRef = collection(db, 'promos');
                const activePromosQuery = query(
                    promosRef,
                    where("active", "==", true),
                    orderBy("created_on", "desc")
                );
                const querySnapshot = await getDocs(activePromosQuery);

                const allPromos: Promo[] = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        cover: data.cover,
                        name: data.name,
                        second_name: data.second_name?.trim() || null, // Use null if blank
                        desc: data.desc,
                        focus: data.focus,
                    };
                });

                const focused = allPromos.find(promo => promo.focus === true) || null;
                const regularPromos = allPromos.filter(promo => promo.focus !== true);

                setFocusedPromo(focused);
                setPromos(regularPromos);
            } catch (error) {
                console.error("Error fetching promos:", error);
            }
        };

        fetchPromos();
    }, []);

    const handleShowMore = () => {
        setDisplayCount(prevCount => prevCount + 6);
    };

    const generateSlug = (name: string) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9\s]/g, '') // Remove special characters
            .replace(/\s+/g, '-');       // Replace spaces with hyphens
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Promos | QD Skinnovations</title>
                <meta name="description" content="View QD Special Promos" />
                <meta name="keywords" content="Promos" />
                <meta name="keywords" content="Offers" />
                <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
                <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
                <link rel="canonical" href="https://qdskinnovations.com/promos" />
            </Helmet>
            {coverImage ? (
                <img src={coverImage} alt="Cover" className="cover-image" />
            ) : (
                <img src="" alt="" className="cover-image" />
            )}
            <div className="promos-page">
                {focusedPromo && (
                    <div className="focused-promo">
                        <Link to={`/promos/${generateSlug(focusedPromo.name)}${focusedPromo.second_name ? `/${generateSlug(focusedPromo.second_name)}` : ''}`}>
                            <img src={focusedPromo.cover} alt={focusedPromo.name} className="focused-cover" />
                        </Link>
                        <div className="focused-info">
                            <Link to={`/promos/${generateSlug(focusedPromo.name)}${focusedPromo.second_name ? `/${generateSlug(focusedPromo.second_name)}` : ''}`} className="focused-name-link">
                                <h2 className="focused-name">{focusedPromo.name}</h2>
                            </Link>
                            <p className="focused-desc">{focusedPromo.desc}</p>
                            <Link to={`/promos/${generateSlug(focusedPromo.name)}${focusedPromo.second_name ? `/${generateSlug(focusedPromo.second_name)}` : ''}`} className="learn-more-button">
                                Learn More
                            </Link>
                        </div>
                    </div>
                )}

                <div className="promos-container">
                    {promos.slice(0, displayCount).map((promo) => (
                        <div key={promo.id} className="promo-item">
                            <Link
                                to={
                                    promo.second_name
                                        ? `/promos/${generateSlug(promo.name)}/${generateSlug(promo.second_name)}`
                                        : `/promos/${generateSlug(promo.name)}`
                                }
                            >
                                <img src={promo.cover} alt={promo.name} className="promo-cover" />
                            </Link>
                            <Link
                                to={
                                    promo.second_name
                                        ? `/promos/${generateSlug(promo.name)}/${generateSlug(promo.second_name)}`
                                        : `/promos/${generateSlug(promo.name)}`
                                }
                                className="promo-name-link"
                            >
                                <h3 className="promo-name">{promo.name}</h3>
                            </Link>
                        </div>
                    ))}
                </div>

                {displayCount < promos.length && (
                    <button onClick={handleShowMore} className="show-more-button">
                        See More
                    </button>
                )}
            </div>
        </div>
    );
};

export default Promos;
