import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, DocumentData, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './SearchResultsPage.css';
import { Helmet } from 'react-helmet';

const SearchResultsPage: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchText = queryParams.get('query') || '';
    const [skincareResults, setSkincareResults] = useState<any[]>([]);
    const [serviceResults, setServiceResults] = useState<any[]>([]);
    const [visibleSkincareResults, setVisibleSkincareResults] = useState<any[]>([]);
    const [visibleServiceResults, setVisibleServiceResults] = useState<any[]>([]);
    const [showMoreSkincare, setShowMoreSkincare] = useState<boolean>(false);
    const [showMoreServices, setShowMoreServices] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const resultsPerPage = 6;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchResults = async () => {
            setIsLoading(true);  // Start loading
            if (searchText) {
                try {
                    const [fetchedSkincareResults, fetchedServiceResults] = await Promise.all([
                        searchSkincareCollection(searchText),
                        searchServicesCollection(searchText)
                    ]);

                    setSkincareResults(fetchedSkincareResults);
                    setServiceResults(fetchedServiceResults);

                    // Initialize displayed results
                    setVisibleSkincareResults(fetchedSkincareResults.slice(0, resultsPerPage));
                    setShowMoreSkincare(fetchedSkincareResults.length > resultsPerPage);

                    setVisibleServiceResults(fetchedServiceResults.slice(0, resultsPerPage));
                    setShowMoreServices(fetchedServiceResults.length > resultsPerPage);

                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            }
            setIsLoading(false);  // End loading
        };

        fetchResults();
    }, [searchText]);

    const searchSkincareCollection = async (searchText: string) => {
        const skincareResults: DocumentData[] = [];
        const seenIds = new Set<string>();  // Set to track added product IDs
        const lowerCaseSearchText = searchText.toLowerCase();
        const searchFields = [
            'name',
            'desc',
            'information',
            'skinType',
            'productType',
            'benefits',
            'how_to_use',
            'ingredients',
            'key_ingredients',
            'faqs'
        ];

        try {
            const q = query(
                collection(db, 'skincare'),
                where('isVisible', '==', true)
            );
            const querySnapshot = await getDocs(q);

            for (const field of searchFields) {
                for (const docSnap of querySnapshot.docs) {
                    const data = docSnap.data();
                    const docId = docSnap.id;

                    if (seenIds.has(docId)) continue; // Skip if the product is already in the results

                    if (field === 'skinType' || field === 'productType') {
                        // Check map fields
                        const mapFieldValues = Object.values(data[field] || {});
                        if (mapFieldValues.some(value =>
                            typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText))) {
                                skincareResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (field === 'key_ingredients' || field === 'faqs') {
                        // Check reference fields
                        const refDocs = await Promise.all((data[field] || []).map((ref: any) => getDoc(ref)));
                        if (refDocs.some(refDoc => refDoc.exists() &&
                            Object.values(refDoc.data()).some(value =>
                                typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText)))) {
                                    skincareResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (data[field] && data[field].toLowerCase().includes(lowerCaseSearchText)) {
                        // Check text fields
                        skincareResults.push({ id: docSnap.id, ...data });
                        seenIds.add(docId); // Add to seen IDs set
                    }
                }
            }
        } catch (error) {
            console.error('Error searching skincare collection:', error);
        }

        return skincareResults;
    };

    const searchServicesCollection = async (searchText: string) => {
        const servicesResults: DocumentData[] = [];
        const seenIds = new Set<string>();  // Set to track added product IDs
        const lowerCaseSearchText = searchText.toLowerCase();
        const searchFields = [
            'name',
            'desc',
            'general_service',
            'types',
            'group',
            'tagline',
            'what_is',
            'benefits',
            'key_info',
            'faqs'
        ];

        try {
            const q = query(
                collection(db, 'services'),
                where('isVisible', '==', true)
            );
            const querySnapshot = await getDocs(q);

            for (const field of searchFields) {
                for (const docSnap of querySnapshot.docs) {
                    const data = docSnap.data();
                    const docId = docSnap.id;

                    if (seenIds.has(docId)) continue; // Skip if the product is already in the results

                    if (field === 'general_service' || field === 'types') {
                        // Check reference fields
                        const refDocs = await Promise.all((data[field] || []).map((ref: any) => getDoc(ref)));
                        if (refDocs.some(refDoc => refDoc.exists() &&
                            Object.values(refDoc.data()).some(value =>
                                typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText)))) {
                                    servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    }
                    else if (field === 'tagline') {
                        // Check map fields
                        const mapFieldValues = Object.values(data[field] || {});
                        if (mapFieldValues.some(value =>
                            typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText))) {
                                servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    }
                    else if (field === 'what_is') {
                        // Check map fields
                        const mapFieldValues = Object.values(data[field] || {});
                        if (mapFieldValues.some(value =>
                            typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText))) {
                                servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (field === 'benefits' || field === 'key_info' || field === 'faqs') {
                        // Check reference fields
                        const refDocs = await Promise.all((data[field] || []).map((ref: any) => getDoc(ref)));
                        if (refDocs.some(refDoc => refDoc.exists() &&
                            Object.values(refDoc.data()).some(value =>
                                typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText)))) {
                                    servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (data[field] && data[field].toLowerCase().includes(lowerCaseSearchText)) {
                        // Check text fields
                        servicesResults.push({ id: docSnap.id, ...data });
                        seenIds.add(docId); // Add to seen IDs set
                    }
                }
            }
        } catch (error) {
            console.error('Error searching services collection:', error);
        }

        return servicesResults;
    };

    const handleShowMoreSkincare = () => {
        const nextResults = skincareResults.slice(0, visibleSkincareResults.length + resultsPerPage);
        setVisibleSkincareResults(nextResults);
        setShowMoreSkincare(nextResults.length < skincareResults.length);
    };

    const handleShowMoreServices = () => {
        const nextResults = serviceResults.slice(0, visibleServiceResults.length + resultsPerPage);
        setVisibleServiceResults(nextResults);
        setShowMoreServices(nextResults.length < serviceResults.length);
    };

    const generateSlug = (name: string) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9\s]/g, '') // Remove special characters
            .replace(/\s+/g, '-');       // Replace spaces with hyphens
    };

    const navigateToResultPageSkincare = (name: string) => {
        navigate(`/products/${generateSlug(name)}`)
    }

    const navigateToResultPageServices = (group: string, name: string) => {
        navigate(`/services/${generateSlug(group)}/${generateSlug(name)}`)
    }

    return (
        <div className="search-results-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{searchText} | QD Skinnovations</title>
                <meta name="description" content="Search Results" />
                <meta name="keywords" content="Search" />
                <meta name="keywords" content="Results" />
                <meta name="keywords" content={searchText} />
                <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
                <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
            </Helmet>
            <h1>Search Results for "{searchText}"</h1>

            <div className="search-results-section">
                <h2>Skincare</h2>
                <div className="search-results-grid">
                    {visibleSkincareResults.map(result => (
                        <div key={result.id} className="search-result-card" onClick={() => navigateToResultPageSkincare(result.name)}>
                            <img src={result.img} alt={result.name} className="search-result-card-image" />
                            <p className="search-result-card-name">{result.name}</p>
                        </div>
                    ))}
                </div>
                {showMoreSkincare && (
                    <button className="see-more-button" onClick={handleShowMoreSkincare}>See More</button>
                )}
            </div>

            <div className="search-results-section">
                <h2>Services</h2>
                <div className="search-results-grid">
                    {visibleServiceResults.map(result => (
                        <div key={result.id} className="search-result-card" onClick={() => navigateToResultPageServices(result.group, result.name)}>
                            <img src={result.img} alt={result.name} className="search-result-card-image" />
                            <p className="search-result-card-name">{result.name}</p>
                        </div>
                    ))}
                </div>
                {showMoreServices && (
                    <button className="see-more-button" onClick={handleShowMoreServices}>See More</button>
                )}
            </div>

            {isLoading && <div className="loading-spinner"></div>}
        </div>
    );
};

export default SearchResultsPage;
