import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, query, where, getDocs, doc, addDoc, Timestamp } from 'firebase/firestore';
import './EventSignUp.css';

const EventSignUp: React.FC = () => {
    const [events, setEvents] = useState<any[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
    const [formVisible, setFormVisible] = useState(false);
    const [clientInfo, setClientInfo] = useState<{ [key: string]: string }>({});
    const [timeSlots, setTimeSlots] = useState<string[]>([]);
    const [isBooked, setIsBooked] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const fetchEvents = async () => {
        const eventsSnapshot = await getDocs(query(collection(db, 'events'), where('active', '==', true)));
        const eventsData = eventsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        setEvents(eventsData);
    };

    useEffect(() => {
        fetchEvents();
        const params = new URLSearchParams(location.search);
        const eventName = params.get('eventName');
        if (eventName) {
            const event = events.find((e) => e.name === decodeURIComponent(eventName));
            if (event) {
                handleEventSelection(event); // Automatically select the event
            }
        }
    }, [location.search, events]);

    const handleEventSelection = (event: any) => {
        setSelectedEvent(event);
        setTimeSlots(event.timeslot ? event.timeslot.map((slot: any) => slot.value) : []);
        setFormVisible(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setClientInfo({ ...clientInfo, [e.target.name]: e.target.value });
    };

    const sendTelegramNotification = async (eventDetails: any) => {
        const botToken = '7835833788:AAGDeIFkgexgVFcqcNHk9rZQ6ME6Ip-cK70';
        const chatId = '7505740377';

        const eventDate = eventDetails.date instanceof Timestamp
            ? new Date(eventDetails.date.seconds * 1000)
            : new Date(eventDetails.date);
        const formattedDate = eventDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        const message = `New Event Booking: ${eventDetails.name}\nLocation: ${eventDetails.location}\nDate: ${formattedDate}\nClient Info:\n- First Name: ${clientInfo.firstName}\n- Last Name: ${clientInfo.lastName}\n- Email: ${clientInfo.email}\n- Phone: ${clientInfo.phone}\nTime Slot: ${clientInfo.timeslot || 'None'}`;

        const url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}`;

        try {
            const response = await fetch(url, { method: 'GET' });
            if (response.ok) {
                console.log('Telegram notification sent');
            } else {
                console.log('Failed to send Telegram notification');
            }
        } catch (error) {
            console.error('Error sending Telegram notification:', error);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (!selectedEvent) throw new Error('Event not selected');
            if (timeSlots.length > 0 && !clientInfo.timeslot) throw new Error('Please select a time slot');

            await addDoc(collection(db, 'event_bookings'), {
                firstname: clientInfo.firstName,
                middlename: clientInfo.middleName || '',
                lastname: clientInfo.lastName,
                email: clientInfo.email,
                phone: clientInfo.phone,
                zipcode: clientInfo.zipcode,
                created_on: new Date(),
                event_name: selectedEvent.name,
                event: doc(db, 'events', selectedEvent.id),
                time_selected: clientInfo.timeslot || '',
            });

            await sendTelegramNotification(selectedEvent);
            setClientInfo({});
            setIsBooked(true);
            setShowPopup(true);
        } catch (error) {
            console.error('Error booking event:', error);
            setError('There was an error booking your event. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsBooked(false);
        setShowPopup(false);
        // Reset the form and return to event selection
        setClientInfo({});
        setSelectedEvent(null);
        setFormVisible(false);
    };

    const isFormValid = () => {
        return (
            clientInfo.firstName &&
            clientInfo.lastName &&
            clientInfo.email &&
            clientInfo.phone &&
            clientInfo.zipcode &&
            (timeSlots.length === 0 || clientInfo.timeslot)
        );
    };

    const handleBack = () => {
        setFormVisible(false); // This will go back to the event selection
        setSelectedEvent(null); // Optionally clear selected event
        setClientInfo({}); // Optionally clear form inputs
        navigate('/event-sign-up'); // Navigate back to the event sign-up page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="sign-up-page">
            {!formVisible ? (
                <div className="form-container">
                    <div className="event-selection">
                        <h2>Select an Event to Sign Up</h2>
                        <div className="event-buttons">
                            {events.map((event) => (
                                <button key={event.id} onClick={() => handleEventSelection(event)}>
                                    {event.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="form-container">
                    <button className="back-button" onClick={handleBack}>
                    ⬅
                    </button>
                    <h2>Sign Up for {selectedEvent?.name}</h2>
                    <div className="input-group">
                        <input
                            type="text"
                            name="firstName"
                            value={clientInfo.firstName || ''}
                            onChange={handleChange}
                            placeholder=" "
                            required
                        />
                        <label>First Name</label>
                    </div>

                    <div className="input-group">
                        <input
                            type="text"
                            name="middleName"
                            value={clientInfo.middleName || ''}
                            onChange={handleChange}
                            placeholder=" "
                        />
                        <label>Middle Name (optional)</label>
                    </div>

                    <div className="input-group">
                        <input
                            type="text"
                            name="lastName"
                            value={clientInfo.lastName || ''}
                            onChange={handleChange}
                            placeholder=" "
                            required
                        />
                        <label>Last Name</label>
                    </div>

                    <div className="input-group">
                        <input
                            type="email"
                            name="email"
                            value={clientInfo.email || ''}
                            onChange={handleChange}
                            placeholder=" "
                            required
                        />
                        <label>Email</label>
                    </div>

                    <div className="input-group">
                        <input
                            type="tel"
                            name="phone"
                            value={clientInfo.phone || ''}
                            onChange={handleChange}
                            placeholder=" "
                            required
                        />
                        <label>Phone</label>
                    </div>

                    <div className="input-group">
                        <input
                            type="text"
                            name="zipcode"
                            value={clientInfo.zipcode || ''}
                            onChange={handleChange}
                            placeholder=" "
                            required
                        />
                        <label>Zip Code</label>
                    </div>

                    {timeSlots.length > 0 && (
                        <div className="input-group">
                            <select
                                name="timeslot"
                                value={clientInfo.timeslot || ''}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select a Time Slot</option>
                                {timeSlots.map((slot, index) => (
                                    <option key={index} value={slot}>
                                        {slot}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <button
                        className="event-form-button"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isFormValid() || loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>



                    {isBooked && showPopup && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h2>Appointment Confirmed</h2>
                                <p>Thank you for choosing QD Skinnovations. Your appointment has been successfully booked.</p>
                                <button className="btn-close-modal" onClick={handleCloseModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                    {error && <p className="error">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default EventSignUp;
