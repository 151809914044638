import React, { useState, useEffect } from 'react';
import './Register.css';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { collection, getDocs, query, where, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        const isFormValid = firstName && lastName && email && password && confirmPassword && agreeTerms &&
            !emailError && !passwordError && !confirmPasswordError;

        setIsButtonDisabled(!isFormValid);
    }, [firstName, lastName, email, password, confirmPassword, agreeTerms, emailError, passwordError, confirmPasswordError]);

    const handleRegistration = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            if (password !== confirmPassword) {
                throw new Error('Passwords do not match');
            }

            const emailExists = await checkEmailExists(email);
            if (emailExists) {
                setEmailError('This email is already used');
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await updateProfile(user, {
                displayName: `${capitalize(firstName)} ${capitalize(lastName)}`
            });

            await setDoc(doc(db, 'users', user.uid), {
                fname: capitalize(firstName),
                lname: capitalize(lastName),
                email: email,
                photoURL: 'https://firebasestorage.googleapis.com/v0/b/qdskinnovations-6f04d.appspot.com/o/profile%2FUser.png?alt=media&token=48749462-3446-4179-954c-be70c8e3e7a9'
            });

            await sendEmailVerification(auth.currentUser!);

            setMessage('Registration successful. Please check your email to verify your account.');
            setTimeout(() => {
                navigate('/login');
            }, 3000); // Redirect after 3 seconds
        } catch (error: any) {
            if (error.code === 'auth/email-already-in-use') {
                setEmailError('This email is already used');
            } else {
                console.error('Error registering user:', error.message);
                setError(error.message);
            }
        }
    };

    const checkEmailExists = async (email: string) => {
        const usersCollection = collection(db, 'users');
        const q = query(usersCollection, where('email', '==', email));
        const querySnapshot = await getDocs(q);

        return !querySnapshot.empty;
    };

    const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setEmail(email);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please provide a properly formatted email address');
            return;
        }

        const emailExists = await checkEmailExists(email);
        if (emailExists) {
            setEmailError('This email is already used');
        } else {
            setEmailError(null);
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setPassword(password);

        if (password && password.length < 8) {
            setPasswordError('Password must be at least 8 characters or more');
        } else {
            setPasswordError(null);
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);

        if (password && confirmPassword && password !== confirmPassword) {
            setConfirmPasswordError('The password does not match');
        } else {
            setConfirmPasswordError(null);
        }
    };

    const capitalize = (text: string) => {
        return text.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return (
        <div className="register-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Register | QD Skinnovations</title>
                <meta name="description" content="Create an Account at QD Skinnovations" />
                <meta name="keywords" content="Register" />
                <meta name="keywords" content="Sign Up" />
                <meta name="keywords" content="Register Form" />
                <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
                <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
            </Helmet>
            <h1 className='createLabel'>Create Account</h1>
            <form className="register-form" onSubmit={handleRegistration}>
                <label htmlFor="firstName">First Name</label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(capitalize(e.target.value))}
                />

                <label htmlFor="lastName">Last Name</label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(capitalize(e.target.value))}
                />

                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    className={emailError ? 'input-error' : ''}
                />
                {emailError && <span className="error-message">{emailError}</span>}

                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    className={passwordError ? 'input-error' : ''}
                />
                {passwordError && <span className="error-message">{passwordError}</span>}

                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className={confirmPasswordError ? 'input-error' : ''}
                />
                {confirmPasswordError && <span className="error-message">{confirmPasswordError}</span>}

                <label className="checkbox-container">
                    <input
                        type="checkbox"
                        checked={agreeTerms}
                        onChange={() => setAgreeTerms(!agreeTerms)}
                    />
                    I agree to the terms and conditions
                </label>

                {error && <p className="error-message">{error}</p>}
                {message && <p className="message">{message}</p>}

                <button type="submit" className="createaccount-button" disabled={isButtonDisabled}>
                    Create Account
                </button>
            </form>
        </div>
    );
};

export default Register;
