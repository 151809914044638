import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './PromoDetail.css';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from "firebase/firestore";
import ReactMarkdown from 'react-markdown';
import BookModal from '../components/BookModal';
import { Helmet } from 'react-helmet';

interface Promo {
    id: string;
    cover: string;
    name: string;
    second_name: string;
    desc: string;
    info: string;
    slug?: string;
    secondSlug?: string | null; // Allow null
}


const generateSlug = (name: string) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '') // Remove special characters
        .replace(/\s+/g, '-');       // Replace spaces with hyphens
};

const PromoDetail: React.FC = () => {
    const { promoSlug, secondNameSlug } = useParams<{ promoSlug: string; secondNameSlug?: string }>();
    const [promo, setPromo] = useState<Promo | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchPromo = async () => {
            try {
                const promosRef = collection(db, 'promos');
                const activePromosQuery = query(promosRef, where("active", "==", true));
                const querySnapshot = await getDocs(activePromosQuery);

                // Map and generate slugs
                const allPromos = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        cover: data.cover,
                        name: data.name,
                        second_name: data.second_name,
                        desc: data.desc,
                        info: data.info,
                        slug: generateSlug(data.name),
                        secondSlug: data.second_name ? generateSlug(data.second_name) : null,
                    };
                });

                // Match promo based on slugs
                const matchedPromo = allPromos.find(promo =>
                    promo.slug === promoSlug &&
                    (!secondNameSlug || promo.secondSlug === secondNameSlug) // Match secondSlug if provided
                );

                if (matchedPromo) {
                    setPromo(matchedPromo);
                } else {
                    console.error(
                        `Promo not found for slug: "${promoSlug}"${secondNameSlug ? ` and secondSlug: "${secondNameSlug}"` : ''}`
                    );
                }
            } catch (error) {
                console.error("Error fetching promos:", error);
            }
        };

        fetchPromo();
    }, [promoSlug, secondNameSlug]);

    const handleBookNow = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className="promo-detail-page">
            {promo ? (
                <>
                    <div className="promo-detail-content">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{promo.name}</title>
                            <meta name="description" content={promo.info} />
                            <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
                            <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
                            <link
                                rel="canonical"
                                href={`https://qdskinnovations.com/promos/${promoSlug}${promo.secondSlug ? `/${promo.secondSlug}` : ''}`}
                            />
                        </Helmet>

                        <div className='item-image-container-promo'>
                            <img src={promo.cover} alt={promo.name} className="promo-detail-cover" />
                        </div>
                        <div className="promo-detail-info">
                            <div className='item-header'>
                                <h2 className="promo-name">{promo.name}</h2>
                            </div>
                            <div className='item-description'>
                                <p className="promo-desc">{promo.desc}</p>
                                <ReactMarkdown className="promo-info">{promo.info}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                    <button className="book-now-button" onClick={handleBookNow}>Book Now</button>
                    <div className='promo-modal'>
                        {isModalOpen && <BookModal onClose={handleCloseModal} promoId={promo.id} />}
                    </div>
                </>
            ) : (
                <p>Loading promo details...</p>
            )}
        </div>
    );
};

export default PromoDetail;
