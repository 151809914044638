// src/App.tsx
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Membership from './pages/Membership';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Register from './pages/Register';
import './App.css';
import './assets/styles/fonts.css';
import './firebaseConfig';
import SearchResultsPage from './pages/SearchResultsPage';
import EventSignUp from './pages/EventSignUp';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Promos from './pages/Promos';
import PromoDetail from './pages/PromoDetail';


// Lazy load your page components
const Home = lazy(() => import('./pages/Home'));
const Skincare = lazy(() => import('./pages/Skincare'));
const Gallery = lazy(() => import('./pages/Gallery'));
const Products = lazy(() => import('./pages/Products'));
const Profile = lazy(() => import('./pages/Profile'));
const Medspa = lazy(() => import('./pages/Medspa'));
const Services = lazy(() => import('./pages/Services'));
const Service = lazy(() => import('./pages/Service'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Events = lazy(() => import('./pages/Events'));
const EventDetails = lazy(() => import('./pages/EventDetails'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogContent = lazy(() => import('./pages/BlogContent'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Reviews = lazy(() => import('./pages/Reviews'));
const SearchResults = lazy(() => import('./pages/SearchResultsPage'));
const BookApointment = lazy(() => import('./pages/Booking'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const PrivacyPoiicy = lazy(() => import('./pages/PrivacyPolicy'));
const Checkout = lazy(() => import('./pages/Checkout'));


function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/skincare" element={<Skincare />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/products/:id" element={<Products />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/services" element={<Medspa />} />
            <Route path="/services/:category" element={<Medspa />} />
            <Route path="/services/:category/:service" element={<Services />} />
            <Route path="/service/:id" element={<Service />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/events" element={<Events />} />
            <Route path="/event_details/:id" element={<EventDetails />} />
            <Route path="/event-sign-up" element={<EventSignUp />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogContent />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/search-results" element={<SearchResultsPage />} />
            <Route path="/book-an-appointment" element={<BookApointment />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPoiicy />} />
            <Route path="/checkout/:id" element={<Checkout />} />
            <Route path="/checkout/success" element={<Success />} />
            <Route path="/checkout/cancel" element={<Cancel />} />
            <Route path="/promos" element={<Promos />} />
            <Route path="/promos/:promoSlug/:secondNameSlug?" element={<PromoDetail />} />
            {/* Add more routes as needed */}
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
