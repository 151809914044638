// Success.tsx
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import check from '../assets/checked.png';
import '../pages/Success.css';
import { Helmet } from 'react-helmet';

const Success: React.FC = () => {
  const location = useLocation();
  const cartId = new URLSearchParams(location.search).get('cartId');
  

  return (
    <div className="success-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Payment Successful | QD Skinnovations</title>
        <meta name="description" content="Transaction was successful!" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
        <link rel="canonical" href={`https://qdskinnovations.com/checkout/success/${cartId}`} />
      </Helmet>
      <img src={check} alt="Success" className="success-logo" />
      <div className="success-text">Your transaction was successful!</div>
    </div>
  );
};

export default Success;
