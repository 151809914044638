import React, { useEffect, useState } from 'react';
import './Cart.css';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, query, where, collection, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { User, onAuthStateChanged } from 'firebase/auth';
import { FaTrash } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { loadStripe } from '@stripe/stripe-js';

interface CartProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SkincareProduct {
  product_id: string;
  quantity: number;
}

interface ProductDetails {
  img: string;
  name: string;
  price: number;
  desc: string;
}

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = React.useState<SkincareProduct[]>([]);
  const [productDetails, setProductDetails] = React.useState<ProductDetails[]>([]);
  const [cartDocId, setCartDocId] = React.useState<string | null>(null); 
  const [currentUser, setCurrentUser] = useState(auth.currentUser); // Initial user state
  const [user, setUser] = React.useState<User | null>(null);


  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const getSessionId = () => {
    let sessionId = localStorage.getItem('session_id');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('session_id', sessionId || "");
    }
    return sessionId;
  };

  useEffect(() => {
    // Set up auth state listener for current user
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      console.log('User status updated:', user);
    });
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    const fetchCartItems = async () => {
      const sessionId = getSessionId();

      try {
        if (user) {
          const userId = user.uid;
          const userRef = doc(db, 'users', userId);
          const cartRef = collection(db, 'cart');
          const q = query(
          cartRef,
          where('user_id', '==', userRef),
          where('status', '==', 'Cart') // Filter for status 'Cart'
        );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const cartData = querySnapshot.docs[0].data();
            const cartDocId = querySnapshot.docs[0].id;  // Save the cart document ID
            setCartDocId(cartDocId);

            if (cartData.skincare_product && Array.isArray(cartData.skincare_product)) {
              const skincareProducts: SkincareProduct[] = cartData.skincare_product.map((item: any) => ({
                product_id: item.value.product_id.path,
                quantity: item.value.quantity,
              }));

              setCartItems(skincareProducts);

              const fetchedProductDetails: ProductDetails[] = await Promise.all(
                skincareProducts.map(async (product) => {
                  const productRef = doc(db, product.product_id);
                  const productSnap = await getDoc(productRef);

                  if (productSnap.exists()) {
                    const productData = productSnap.data() as ProductDetails;
                    return {
                      img: productData.img,
                      name: productData.name,
                      price: productData.price,
                      desc: productData.desc,
                    };
                  } else {
                    return { img: '', name: '', price: 0, desc: '' };
                  }
                })
              );

              setProductDetails(fetchedProductDetails);
            } else {
              setCartItems([]);
              setProductDetails([]);
            }
          } else {
            setCartItems([]);
            setProductDetails([]);
          }
        } else {
          const cartRef = collection(db, 'cart');
          const q = query(
            cartRef,
            where('session_id', '==', sessionId),
            where('status', '==', 'Cart') // Filter for status 'Cart'
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const cartData = querySnapshot.docs[0].data();
            const cartDocId = querySnapshot.docs[0].id;  // Save the cart document ID
            setCartDocId(cartDocId);

            if (cartData.skincare_product && Array.isArray(cartData.skincare_product)) {
              const skincareProducts: SkincareProduct[] = cartData.skincare_product.map((item: any) => ({
                product_id: item.value.product_id.path,
                quantity: item.value.quantity,
              }));

              setCartItems(skincareProducts);

              const fetchedProductDetails: ProductDetails[] = await Promise.all(
                skincareProducts.map(async (product) => {
                  const productRef = doc(db, product.product_id);
                  const productSnap = await getDoc(productRef);

                  if (productSnap.exists()) {
                    const productData = productSnap.data() as ProductDetails;
                    return {
                      img: productData.img,
                      name: productData.name,
                      price: productData.price,
                      desc: productData.desc,
                    };
                  } else {
                    return { img: '', name: '', price: 0, desc: '' };
                  }
                })
              );

              setProductDetails(fetchedProductDetails);
            } else {
              setCartItems([]);
              setProductDetails([]);
            }
          } else {
            setCartItems([]);
            setProductDetails([]);
          }
        }
      } catch (error) {
        console.error('Error fetching cart items: ', error);
      }
    };

    if (isOpen) {
      fetchCartItems();
    }
  }, [user, isOpen]);

  const calculateSubtotal = () => {
    const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    const totalPrice = cartItems.reduce((acc, item, index) => {
      const productPrice = productDetails[index]?.price || 0;
      return acc + (productPrice * item.quantity);
    }, 0);
    return { totalItems, totalPrice };
  };

  const { totalItems, totalPrice } = calculateSubtotal();

  const handleQuantityChange = async (index: number, change: number) => {
    const newCartItems = [...cartItems];
    const newQuantity = newCartItems[index].quantity + change;

    if (newQuantity > 0) {
        newCartItems[index].quantity = newQuantity;
        setCartItems(newCartItems);

        try {
            let cartDocRef;
            let skincareProducts = [];

            if (user) {
                // User is logged in, update cart based on user_id
                const userId = user.uid;
                const userRef = doc(db, 'users', userId);
                const cartRef = collection(db, 'cart');
                const q = query(cartRef, where('user_id', '==', userRef));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const cartData = querySnapshot.docs[0];
                    cartDocRef = doc(cartRef, cartData.id);
                    skincareProducts = cartData.data().skincare_product || [];
                }
            } else {
                // User is not logged in, update cart based on session_id
                const sessionId = getSessionId();  // Retrieve session ID from local storage
                const cartRef = collection(db, 'cart');
                const q = query(cartRef, where('session_id', '==', sessionId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const cartData = querySnapshot.docs[0];
                    cartDocRef = doc(cartRef, cartData.id);
                    skincareProducts = cartData.data().skincare_product || [];
                }
            }

            if (cartDocRef && skincareProducts.length > 0) {
                // Update the quantity in the skincare product array
                skincareProducts[index].value.quantity = newQuantity;

                // Update the cart document in Firestore
                await updateDoc(cartDocRef, {
                    skincare_product: skincareProducts,
                });
            }
        } catch (error) {
            console.error('Error updating quantity in Firestore: ', error);
        }
    } else if (newQuantity === 0) {
        // If the quantity is 0, remove the product from the cart
        const product_id = newCartItems[index].product_id;
        await handleDeleteProduct(product_id);
    }
};




  const handleDeleteProduct = async (product_id: string) => {
    try {
        let cartDocRef;

        if (user) {
            // User is logged in, delete based on user_id
            const userId = user.uid;
            const userRef = doc(db, 'users', userId);
            const cartRef = collection(db, 'cart');
            const q = query(cartRef, where('user_id', '==', userRef),
            where('status', '==', 'Cart'));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const cartData = querySnapshot.docs[0];
                cartDocRef = doc(cartRef, cartData.id);
            }
        } else {
            // User is not logged in, delete based on session_id
            const sessionId = getSessionId();  // Retrieve session ID from local storage
            const cartRef = collection(db, 'cart');
            const q = query(cartRef, where('session_id', '==', sessionId),
            where('status', '==', 'Cart'));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const cartData = querySnapshot.docs[0];
                cartDocRef = doc(cartRef, cartData.id);
            }
        }

        if (cartDocRef) {
            // Filter out the product from the cart
            const updatedSkincareProducts = cartItems
                .filter(item => item.product_id !== product_id)
                .map(item => ({
                    type: "product",
                    value: {
                        product_id: doc(db, item.product_id),
                        quantity: item.quantity,
                    },
                }));

            // Update the cart document in Firestore
            await updateDoc(cartDocRef, {
                skincare_product: updatedSkincareProducts,
            });

            // Update state after successful deletion
            const newCartItems = cartItems.filter(item => item.product_id !== product_id);
            const newProductDetails = productDetails.filter((_, index) => cartItems[index].product_id !== product_id);

            setCartItems(newCartItems);
            setProductDetails(newProductDetails);
            console.log(`Product ${product_id} removed from cart`);
        }
    } catch (error) {
        console.error('Error deleting product from cart: ', error);
    }
};




  const handleShopNowClick = () => {
    onClose();
    navigate('/skincare');
  };

  const generateSlug = (name: string) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '') // Remove special characters
        .replace(/\s+/g, '-');       // Replace spaces with hyphens
};

const handleProductClick = (name: string) => {
    onClose();
    const slug = generateSlug(name);
    navigate(`/products/${slug}`);
};



const stripePromise = loadStripe('pk_test_51QJrZgGpKIvAqfAF6aTyZwAIzNmVfUx0Jmcu5Vfe6Gj5lzxmHp9L9WlRlDYzUNOJCpunsYLKF33E102IMO6iFHYl004llHjxpQ');

const handleCheckout = async () => {
  const stripe = await stripePromise;

  if (!stripe) {
    console.error('Stripe has not loaded yet.');
    return;
  }

  const itemsToCheckout = cartItems.map((item, index) => ({
    name: productDetails[index]?.name,
    img: productDetails[index]?.img,
    price: productDetails[index]?.price,
    quantity: item.quantity,
  }));

  // Log cartDocId and currentUser for debugging
  console.log('cartDocId:', cartDocId);
  console.log('currentUser UID:', currentUser ? currentUser.uid : 'No user logged in');

  try {
    // Call backend to create a Checkout Session, passing the cart ID and user ID
    const response = await fetch('http://localhost:3001/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: itemsToCheckout,
        cartId: cartDocId,
        userId: currentUser ? currentUser.uid : null,
      }),
    });

    const session = await response.json();

    // Redirect to Checkout
    if (session.id) {
      const result = await stripe.redirectToCheckout({ sessionId: session.id });
      if (result.error) {
        console.error(result.error.message);
      }
    }
  } catch (error) {
    console.error('Error during checkout:', error);
  }
};


  return (
    <>
      <div className={`cart-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}></div>
      <div className={`cart ${isOpen ? 'open' : ''}`}>
        <div className="cart-header">
          <h2>Cart</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <hr className="divider" />
        <div className="cart-body">
          {cartItems.length === 0 ? (
            <div className="empty-cart">
              <p className="empty-text">Your cart is empty!</p>
              <p>Add skincare products to your cart</p>
              <button className="shop-now-btn" onClick={handleShopNowClick}>
                Shop Now
              </button>
            </div>
          ) : (
            <div className="cart-items">
              {cartItems.map((item, index) => (
                <div key={index} className="cart-item">
                  <img src={productDetails[index]?.img} alt={productDetails[index]?.name} className="cart-item-img" onClick={() => handleProductClick(productDetails[index]?.name)}/>
                  <div className="cart-item-details">
                    <h3 className='cart-product-name' onClick={() => handleProductClick(productDetails[index]?.name)}>{productDetails[index]?.name}</h3>
                    <p className='cart-product-desc'>{productDetails[index]?.desc}</p>
                    <div className="quantity-control">
                      <button onClick={() => handleQuantityChange(index, -1)}>-</button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleQuantityChange(index, 1)}>+</button>
                    </div>
                  </div>
                  <div className="cart-item-actions">
                    <button className="delete-btn" onClick={() => handleDeleteProduct(item.product_id)}>
                      <FaTrash />
                    </button>
                    <p className="product-price-cart">${(productDetails[index]?.price * item.quantity).toFixed(2)}</p>
                  </div>

                </div>
              ))}
            </div>
          )}
        </div>

        <div className="cart-footer">
          <div className="subtotal">
            <p className="subtotal-text">
              Subtotal ({totalItems} items)
            </p>
            <p className="subtotal-price">
              ${totalPrice.toFixed(2)}
            </p>
          </div>
          <button  disabled className="checkout-btn" onClick={handleCheckout}>
            Checkout
          </button>
        </div>
      </div>
    </>
  );
};

export default Cart;
