import React, { useState } from 'react';
import './Login.css';
import { signInWithEmailAndPassword, AuthError, signOut, User } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import defaultProfileImg from '../assets/default_profile.png';
import { Helmet } from 'react-helmet';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false); // New state for loading

    const storeUserData = async (user: User, additionalData?: { fname?: string, lname?: string }) => {
        const userDoc = doc(db, 'users', user.uid);
        const { displayName, email, photoURL } = user;

        let fname = '';
        let lname = '';

        if (displayName) {
            const nameParts = displayName.split(' ');
            fname = nameParts[0];
            lname = nameParts.slice(1).join(' ');
        }

        await setDoc(userDoc, {
            fname: additionalData?.fname || fname,
            lname: additionalData?.lname || lname,
            email: email,
            photoURL: photoURL || defaultProfileImg,
        });
    };

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true); // Set loading state to true

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                setError('Please verify your email. Check your inbox for verification instructions.');
                await signOut(auth);
            } else {
                // Redirect to authenticated page after successful login
                // Replace '/dashboard' with your desired authenticated route
                window.location.href = '/';
            }
        } catch (error: any) {
            handleLoginError(error);
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    const handleLoginError = (error: AuthError) => {
        setPassword('');
        setIsLoading(false); // Reset loading state in case of error

        switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/user-disabled':
            case 'auth/user-not-found':
            case 'auth/wrong-password':
                setError('Incorrect email or password. Please try again.');
                break;
            default:
                setError('Incorrect email or password. Please try again.');
                break;
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login | QD Skinnovations</title>
                <meta name="description" content="Login to your account at QD Skinnovations" />
                <meta name="keywords" content="Login" />
                <meta name="keywords" content="Sign In" />
                <meta name="keywords" content="Login Form" />
                <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
                <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
            </Helmet>
            <h1 className="loginLabel">Login</h1>
            <form className="login-form" onSubmit={handleLogin}>
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label htmlFor="password">Password</label>
                <div className="password-input-container login-form">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="password-input"
                    />
                    <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        className="password-toggle"
                        onClick={togglePasswordVisibility}
                    />
                </div>

                <div className="forgot-password">
                    <Link to="/forgotpassword">Forgot Password?</Link>
                </div>

                <button type="submit" className="login-button" disabled={isLoading}>
                    {isLoading ? <div className="loading-animation-login"></div> : 'Login'}
                </button>

                {error && <div className="error-box">{error}</div>}

                <div className="or-separator">or</div>

                <Link to="/register">
                    <button type="button" className="create-account-button">
                        Create an Account
                    </button>
                </Link>
            </form>
        </div>
    );
};

export default Login;
