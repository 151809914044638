// Cancel.tsx
import React from 'react';
import cancel from '../assets/cancel.png';
import '../pages/Cancel.css';
import { Helmet } from 'react-helmet';

const Cancel: React.FC = () => {
    return (
        <div className="cancel-container">
            <Helmet>
        <meta charSet="utf-8" />
        <title>Payment Cancelled | QD Skinnovations</title>
        <meta name="description" content="transaction was cancelled!" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
        <link rel="canonical" href="https://qdskinnovations.com/checkout/cancel" />
      </Helmet>
            <img src={cancel} alt="Cancelled" className="cancel-logo" />
            <div className="cancel-text">Your transaction was cancelled.</div>
        </div>
    );
};

export default Cancel;
