import React, { useEffect, useState } from 'react';
import './Membership.css';
import { loadStripe } from '@stripe/stripe-js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Helmet } from 'react-helmet';
import cover from '../assets/shop_skincare_cover.png';
import Image from '../assets/image1.jpg';

const Membership: React.FC = () => {
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [coverDescription, setCoverDescription] = useState<string | null>(null);

  useEffect(() => {
    const fetchCoverData = async () => {
      try {
        const coverCollection = collection(db, 'cover');
        const coverQuery = query(coverCollection, where('page_name', '==', 'membership'));
        const coverSnapshot = await getDocs(coverQuery);

        coverSnapshot.forEach(doc => {
          const data = doc.data();
          setCoverImage(data.cover);
          setCoverDescription(data.description);
        });
      } catch (error) {
        console.error("Error fetching cover data: ", error);
      }
    };

    fetchCoverData();
  }, []);

  const stripePromise = loadStripe('pk_test_51QJrZgGpKIvAqfAF6aTyZwAIzNmVfUx0Jmcu5Vfe6Gj5lzxmHp9L9WlRlDYzUNOJCpunsYLKF33E102IMO6iFHYl004llHjxpQ');

  const handleJoinNow = async (plan: string) => {
    const stripe = await stripePromise;
    if (!stripe) {
      console.error("Stripe failed to initialize.");
      return;
    }

    try {
      const response = await fetch('http://localhost:3001/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: [
            {
              name: `${plan} Membership`,
              img: "your-image-url",
              price: plan === 'QD Glow' ? 199 : plan === 'QD Renew' ? 350 : 499,
              quantity: 1,
            },
          ],
        }),
      });

      const session = await response.json();
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Membership | QD Skinnovations</title>
        <meta name="description" content="Join VIP Membership" />
        <meta name="keywords" content="VIP, Membership, Join VIP, Join Membership" />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <link rel="icon" href="https://qdskinnovations.com/favicon.ico" />
        <link rel="canonical" href="https://qdskinnovations.com/membership" />
      </Helmet>

      {coverImage ? (
        <img src={coverImage} alt="Cover" className="cover-image" />
      ) : (
        <img src={cover} alt="Cover" className="cover-image" />
      )}

      <div className="paragraph-box">
        <p>{coverDescription || ''}</p>
      </div>

      <div className="membership-container">
        {[
          { name: "QD Glow", price: "$199/month", discount: 10, priceId: "price_id_glow" },
          { name: "QD Renew", price: "$350/month", discount: 15, priceId: "price_id_renew", popular: true },
          { name: "QD Radiance", price: "$499/month", discount: 20, priceId: "price_id_radiance" },
        ].map((plan) => (
          <div key={plan.name} className="membership-column">
            <img src={Image} alt={`Membership ${plan.name}`} className="membership-image" />
            <h2 className="membership-title">
              {plan.name} {plan.popular && <span className="popular">(Most Popular!)</span>}
              <br />
              <span className="vip-price">{plan.price}</span>
            </h2>
            <p className="membership-details">
              As a {plan.name} member, you are able to apply the monthly amount you banked in your account toward any treatment and receive:
              <br />
              <br />
              <span className="percentage">-{plan.discount}%</span> savings from the regular price services
              <br />
              <span className="percentage">-{plan.discount}%</span> savings on all retail purchases
              <br />
              <br />
              <span className="benefits">Program Benefits:</span>
              <br />
              - Complimentary Skin Analysis
              <br />
              - Complimentary cosmetic consultations
              <br />
              - Exclusive Member-only offers throughout the year
              <br />
              - Members are chosen for complimentary treatments/services during events
              <br />
              - Access to VIP-only Events
            </p>
            <button className="membership-button" onClick={() => handleJoinNow(plan.name)}>
              Join Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Membership;
