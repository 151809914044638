import React from 'react';
import './Footer.css';
import logo from '../assets/footer_files/Logo-Sqr.png';
import { Link } from 'react-router-dom';
import fb from '../assets/fb_logo.png';
import ig from '../assets/ig_logo.png';
import tt from '../assets/tiktok_logo.png';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-location">
          <h4>Location</h4>
          <p>21039 S Figueroa St Suite 201, Carson, CA 90745, United States</p>
          <p>424-489-4569</p>

          <div className="social-links">
            <Link to='https://www.facebook.com/qdskinnovationsfb/' target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="Facebook Link" className="social-link-btn"></img>
            </Link>
            <Link to='https://www.instagram.com/qdskinnovations' target="_blank" rel="noopener noreferrer">
              <img src={ig} alt="Instagram Link" className="social-link-btn"></img>
            </Link>
            <Link to='https://www.tiktok.com/@qd.skinnovations' target="_blank" rel="noopener noreferrer">
              <img src={tt} alt="TikTok Link" className="social-link-btn"></img>
            </Link>
            
          </div>
        </div>
        <div className="footer-links">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/services">Shop MedSpa Services</Link></li>
            <li><Link to="/skincare">Shop Skincare</Link></li>
            <li><Link to="/reviews">Reviews</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/membership">Join VIP Membership</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
            <li><Link to="/events">Events</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="/promos">Promos</Link></li>
          </ul>
        </div>
        <div className="footer-logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 QD Skinnovations. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
